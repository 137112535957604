export default {
    en: {
      translationButton: 'EN',

      titleFirstLine: "Great ventures",
      titleFirstPart: 'await ',
      titleSecondPart: 'the bold.',

      paragraph: 'Join the orbit of entrepreneurship, connect with your ideal future partners. Antoo by entrepreneurs, for entrepreneurs.',
      paragraphLine01: 'Join the orbit of entrepreneurship, connect with your ideal future partners.',
      paragraphLine02: 'your ideal future partners.',
      paragraphLine03: 'Antoo by entrepreneurs, for entrepreneurs.',

      inputPlaceholder: 'Your Email Adress',
      inputButton: 'Join the Voyage',
    },

    fr: {
      translationButton: 'FR',

      titleFirstLine: "De grandes aventures",
      titleFirstPart: 'attendent ',
      titleSecondPart: 'les audacieux.',

      paragraph: 'Rejoignez l\'orbite de l\'entrepreunariat, connectez-vous avec vos futurs partenaires idéaux. Antoo par des entrepreneurs, pour des entrepreneurs.',
      paragraphLine01: 'Rejoignez l\'orbite de l\'entrepreunariat, connectez-vous avec vos futurs partenaires idéaux.',
      paragraphLine02: 'vos futurs partenaires idéaux.',
      paragraphLine03: 'Antoo par des entrepreneurs, pour des entrepreneurs.',

      inputPlaceholder: 'Votre adresse e-mail',
      inputButton: 'Rejoindre le voyage',
    },
  }
  