<template>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Inter:wght@100..900&display=swap" rel="stylesheet">
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
  <body style="user-select: none;">
    <IndexPage/>
  </body>
</template>

<script>
import IndexPage from './components/Index.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>
#app {
  font-family: Inter, Inika;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/Nuée_Etoile.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.4;
}

@media (max-width: 1200px) {
  body::before {
    background-size: cover;
  }
}

body {
  background-color: #0A0A0A;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

</style>
