<template>
  <button class="translation-button" @click="switchLocale">{{ $t('translationButton') }}</button>
  <img src="@/assets/antoo_logo.png" alt="Logo Antoo" class="logo-antoo-png">

  <div class="content">
    <div class="index">
      <p>
        <span class="inter-regular">{{ $t('titleFirstLine') }}<br>{{ $t('titleFirstPart') }}</span>
        <span class="inika-bold">{{ $t('titleSecondPart') }}</span>
      </p>
      <div class="container">
        <p class="para-grey">
          {{ $t('paragraphLine01') }}<br>{{ $t('paragraphLine03') }}
        </p>

        <div class="input-field">
          <input type="text" :placeholder="$t('inputPlaceholder')" class="input-email" v-model="email">
          <button class="input-button" @click="sendEmail">{{ $t('inputButton') }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="lueur-planet">
    <img src="@/assets/lueur_forte_planet_svg.svg" alt="Lueur Planet SVG" class="lueur-planet-svg">
  </div>

  <div class="lueur-entiere">
    <img src="@/assets/lueur_entière_svg.svg" alt="Lueur Entiere SVG" class="lueur-entiere-svg">
  </div>

  <div class="planet">
    <img src="@/assets/lueur_planet_svg.svg" alt="Planet SVG" class="planet-svg">
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  props: {
  },
  methods: {
    switchLocale() {
      console.log(this.$i18n.locale)
      if (this.$i18n.locale === 'fr') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'fr'
      }
      this.$forceUpdate()
    },
    sendEmail() {
      if (!this.email) {
        alert('Please enter an email address');
        return;
      }

      console.log(JSON.stringify({ email: this.email }));
      fetch('https://antoo.net/emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: this.email }),
      })
      .then(response => {
        if (response.ok) {
          alert('Email address successfully added');
        } else {
          alert('Failed to save your email address');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to save your email address');
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*-------- Fonts CSS section --------*/
.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  font-size: 60px;
}
.inika-regular {
  font-family: "Inika", serif;
  font-weight: 400;
  font-style: normal;
}
.inika-bold {
  font-family: "Inika", serif;
  font-weight: 800;
  font-style: normal;
  font-size: 60px;
}
.para-grey {

  color: #8C8C8C;
  font-size: 20px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/*Style for the antoo logo */
.logo-antoo-png {
  position: fixed;
  top: -25px;
  left: -20px;
  scale: 40%;
}

/* Styles for screens larger than 1200px */
@media (min-width: 600px) {

  /*-------- Translation button CSS section --------*/
  .translation-button {
    position: fixed;
    top: 30px;
    right: 40px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    color: white;
    padding: 10px;
    font-weight: bold;
    width: 70px;
    height: 50px;
    z-index: 1;
  }
  .translation-button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #0A0A0A;
    cursor: pointer;
  }

  /*-------- Container CSS section --------*/
  .container {
    display: flex;
    flex-direction: column;
  }

  /*-------- Input field CSS section --------*/
  .input-field {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 400px;
    margin-top: 10px;
  }
  .input-email {
    padding: 17px;

    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    z-index: 1;
    background-color: #0A0A0A;
    color: white;
    width: 100%;
  }
  .input-button {
    padding: 17px 20px;
    border: none;
    background-color: white;
    color: #0A0A0A;
    cursor: pointer;
    font-size: 16px;
    z-index: 1;
    border-radius: 25px;

    position: absolute;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
  }
  .input-button:hover {
    background-color: #a8aaa8;
  }

  /*-------- Content CSS section --------*/
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }

  /*-------- Background CSS section --------*/
  .lueur-entiere-svg {
    position: absolute;
    left: 50%;
    transform: translateX(-45%);
    bottom: -10px;
    height: 100%;
    width: 1500px;
    opacity: 0.4;
  }
  .lueur-planet-svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -310px;
    height: 100%;
    width: 1500px;
    opacity: 0.6;
  }
  .planet-svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -470px;
    height: 100%;
    width: 1500px;
  }
}

/* Styles for screens smaller than 1200px */
@media (max-width: 600px) {

  .logo-antoo-png {
    position: fixed;
    top: -50px;
    left: -40px;
    scale: 25%;
  }

  /*-------- Translation button CSS section --------*/
  .translation-button {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    color: white;
    font-weight: bold;
    width: 45px;
    height: 35px;
    z-index: 1;
  }
  .translation-button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #0A0A0A;
    cursor: pointer;
  }

  /*-------- Container CSS section --------*/
  .container {
    display: flex;
    flex-direction: column;
  }

  /*-------- Input field CSS section --------*/
  .input-field {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    margin-top: 0;
  }
  .input-email {
    padding: 17px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    z-index: 1;
    background-color: #0A0A0A;
    color: white;
    width: 80%;
    margin: 0px 10px 0px 0px;
    border: 1px solid #2e2d2d;
  }
  .input-button {
    padding: 17px 20px;
    border: none;
    background-color: white;
    color: #0A0A0A;
    cursor: pointer;
    font-size: 14px;
    z-index: 1;
    border-radius: 25px;
    position: relative;
    margin: auto;
    font-weight: bold;
  }
  .input-button:hover {
    background-color: #a8aaa8;
  }

  /*-------- Content CSS section --------*/
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    margin-top: 60px;
  }

  /*-------- Font CSS section --------*/
  .inter-regular {
    font-size: 40px;
  }
  .inika-bold {
    font-size: 40px;
  }
  .para-grey {
    font-size: 15px;
    margin-bottom: 20px;
  }

  /*-------- Background CSS section --------*/
  .lueur-entiere-svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0px;
    height: 100%;
    width: 1000px;
    opacity: 0.4;
  }
  .lueur-planet-svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -240px;
    height: 100%;
    width: 1000px;
    opacity: 0.6;
  }
  .planet-svg {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: -450px;
    height: 100%;
    width: 1000px;
  }
}

</style>
